import React from "react";
import { useAdmin } from "../../hooks/useAuth";

const CreateAddressModal = ({ closeModal }) => {
  const server_url = 'https://server.nextwebtrade.com';
  const route = "/adress";
  const { isAdmin } = useAdmin();
  const [network, setNetwork] = React.useState("");
  const [hash, setHash] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (network != "BTC" && network != "USDT TRC20" && network != "ETH" && network != "USDT ERC20") {
      alert("Network not allowed choose one from (BTC - USDT TRC20 - ETH - USDT ERC20)")
      return;
    }
    try {
      const response = await fetch(server_url + route, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "network": network, "hash": hash }),
      });
      const json = await response.json();
      if (json.error) {
        alert("Something went wrong, please try again later. error: " + json.error);
        return;
      }
    } catch (err) {
      alert("Something went wrong, please try again later. error: " + err);
      return;
    }
    closeModal();
    window.location.reload();
  };

  React.useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) closeModal();
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return isAdmin ? (
    <div className="edit_modal" onClick={closeModal}>
      <form
        className="edit_modal_form"
        onSubmit={handleSubmit}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label">Network (BTC - USDT TRC20 - ETH - USDT ERC20):</label>
          <input
            className="edit_modal_form_input"
            type="text"
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
          />
        </div>
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label">Address:</label>
          <input
            className="edit_modal_form_input"
            type="text"
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
        </div>
        <div className="edit_modal_form_item">
          <button className="edit_modal_form_button">Add</button>
        </div>
      </form>
    </div>
  )
    :
    (<div className="edit_modal_form_item"> permission denied </div>)
      ;
};

export default CreateAddressModal;
